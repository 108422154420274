var render = function () {
  var _vm$analytics$consump, _vm$analytics$consump2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.analyticsOptions.selectedId ? _c('div', [_vm.loadingAction.getAccountTypes ? _c('SpinnerLogo') : _c('div', [_c('div', {
    staticClass: "row mb-4"
  }, _vm._l(_vm.consumptionCards.slice(0, 4), function (_ref) {
    var _vm$analytics$, _vm$analytics$2, _vm$analytics$3;

    var icon = _ref.icon,
        type = _ref.type;
    return _c('div', {
      key: type,
      staticClass: "col"
    }, [_c('ConsumptionCard', {
      staticClass: "flex-grow-1",
      attrs: {
        "value": (_vm$analytics$ = _vm.analytics["".concat(type, "-consumption")]) === null || _vm$analytics$ === void 0 ? void 0 : _vm$analytics$.totalConsumption,
        "prev-value": (_vm$analytics$2 = _vm.analytics["".concat(type, "-consumption-prev")]) === null || _vm$analytics$2 === void 0 ? void 0 : _vm$analytics$2.totalConsumption,
        "precision": "0",
        "icon-class": icon,
        "description": _vm.capitalize(type),
        "units": type === 'water' ? 'm3' : 'kWh',
        "comparison-period": _vm.analyticsOptions.comparePeriod,
        "value-loading": _vm.loadingAction.getAnalytics["".concat(type, "-consumption")],
        "prev-value-loading": _vm.loadingAction.getAnalytics["".concat(type, "-consumption-prev")],
        "error": _vm.errorAction.getAnalytics["".concat(type, "-consumption")],
        "estimated-percentage": _vm.generateTotalEstimateConsumption(type) / ((_vm$analytics$3 = _vm.analytics["".concat(type, "-consumption")]) === null || _vm$analytics$3 === void 0 ? void 0 : _vm$analytics$3.totalConsumption)
      }
    })], 1);
  }), 0), _c('div', {
    staticClass: "row mb-4"
  }, _vm._l(_vm.consumptionCards.slice(4, 8), function (_ref2) {
    var _vm$analytics$4, _vm$analytics$5, _vm$analytics$6;

    var icon = _ref2.icon,
        type = _ref2.type;
    return _c('div', {
      key: type,
      staticClass: "col"
    }, [_c('ConsumptionCard', {
      staticClass: "flex-grow-1",
      attrs: {
        "value": (_vm$analytics$4 = _vm.analytics["".concat(type, "-consumption")]) === null || _vm$analytics$4 === void 0 ? void 0 : _vm$analytics$4.totalConsumption,
        "prev-value": (_vm$analytics$5 = _vm.analytics["".concat(type, "-consumption-prev")]) === null || _vm$analytics$5 === void 0 ? void 0 : _vm$analytics$5.totalConsumption,
        "precision": "0",
        "icon-class": icon,
        "description": _vm.capitalize(type),
        "units": type === 'water' ? 'm3' : 'kWh',
        "comparison-period": _vm.analyticsOptions.comparePeriod,
        "value-loading": _vm.loadingAction.getAnalytics["".concat(type, "-consumption")],
        "prev-value-loading": _vm.loadingAction.getAnalytics["".concat(type, "-consumption-prev")],
        "error": _vm.errorAction.getAnalytics["".concat(type, "-consumption")],
        "estimated-percentage": _vm.generateTotalEstimateConsumption(type) / ((_vm$analytics$6 = _vm.analytics["".concat(type, "-consumption")]) === null || _vm$analytics$6 === void 0 ? void 0 : _vm$analytics$6.totalConsumption)
      }
    })], 1);
  }), 0)]), _c('div', {
    staticClass: "bg-lighter rounded-md px-4 py-3 mb-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Granularity")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "granularity",
      "type": "select",
      "options": _vm.granularityOptions,
      "disabled": _vm.loadingAction.getConsumptions
    },
    on: {
      "input": _vm.onGranularityUpdate
    },
    model: {
      value: _vm.analyticsOptions.granularity,
      callback: function callback($$v) {
        _vm.$set(_vm.analyticsOptions, "granularity", $$v);
      },
      expression: "analyticsOptions.granularity"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Utility Type")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "accountType",
      "type": "select",
      "options": _vm.typeOptions
    },
    on: {
      "input": _vm.onTypeUpdate
    },
    model: {
      value: _vm.analyticsOptions.accountType,
      callback: function callback($$v) {
        _vm.$set(_vm.analyticsOptions, "accountType", $$v);
      },
      expression: "analyticsOptions.accountType"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Highlight")]), _c('FormItem', {
    staticClass: "mb-0",
    attrs: {
      "id": "highlight",
      "type": "select",
      "options": [{
        label: 'None',
        value: ''
      }, {
        label: 'Source',
        value: 'source'
      }, {
        label: 'Estimated',
        value: 'estimated'
      }, {
        label: 'Compare To',
        value: 'compare'
      }],
      "disabled": _vm.loadingAction.getConsumptions
    },
    on: {
      "input": _vm.refreshChart
    },
    model: {
      value: _vm.filters.highlight,
      callback: function callback($$v) {
        _vm.$set(_vm.filters, "highlight", $$v);
      },
      expression: "filters.highlight"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mr-3 border-right pr-3"
  }, [_c('div', {
    staticClass: "form-static-text pr-3"
  }, [_vm._v("Graph Type")]), _c('a', {
    staticClass: "font-w600 px-3 py-1",
    class: {
      'bg-primary text-white border rounded-md': _vm.filters.chartType === 'bar'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.filters.chartType = 'bar';
      }
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-chart-column mr-1"
  }), _vm._v(" Bar")]), _c('a', {
    staticClass: "font-w600 px-3 py-1",
    class: {
      'bg-primary text-white border rounded-md': _vm.filters.chartType === 'line'
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.filters.chartType = 'line';
      }
    }
  }, [_c('i', {
    staticClass: "fa-solid fa-chart-line mr-1"
  }), _vm._v(" Line")])])])]), !_vm.loading && !_vm.loadingAction.getAnalytics['consumption'] && (_vm$analytics$consump = _vm.analytics['consumption']) !== null && _vm$analytics$consump !== void 0 && (_vm$analytics$consump2 = _vm$analytics$consump.data) !== null && _vm$analytics$consump2 !== void 0 && _vm$analytics$consump2.length ? _c('Chart', {
    staticClass: "mb-4",
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "option": _vm.consumptionChart
    }
  }) : _c('SpinnerLogo')], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }